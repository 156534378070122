const modalsRu = {
  requests: 'Какие запросы разберем?',
  howIsGoingCons: 'Как проходит консультация?',
  buy: 'Купить',

  personal: {
    title: 'Целостная личная матрица',

    firstStep:
      'Первая ступень Матрицы Судьбы является идеальной базой для старта в познании себя и своих глубинных процессов. Матрица отвечает на большинство вопросов, таких как:',
    stepsList1: 'Какие у меня сильные качества?',
    stepsList2: 'Какое моё предназначениe?',
    stepsList3: 'Какие у меня таланты?',
    stepsList4: 'Как мне реализовать себя?',
    stepsList5: 'Как заработать деньги?',
    stepsList6: 'Как построить личную жизнь?',
    stepsList7: 'Куда пойти учиться?',
    stepsList8: 'Могу ли я открыть свой бизнес?',
    stepsList9: 'Почему сложные отношения с родными? И т.д.',
    desc1:
      'Также, матрица расскажет какие склонности, изюминки и сверхспособности заложены в вашей личности. Возможные трудности, испытания и негативные установки с прошлых воплощений, которые нужно проработать и преобразовать в ресурс.',
    desc2:
      'Личная матрица раскроет тему здоровья и психосоматических процессов в вашем теле. Это самая глубокая и самая фундаментальная трансформация в вашей жизни.',
    desc3:
      'В дальнейшем можно наслаивать на эту базу изучения своей личности с помощью других методов, а также спускаться на более глубинные уровни своей души. И выходить на новые уровни понимания и осознанности.',

    videoMeeting: 'Видеовстреча 2 - 3 часа + трансформация от 60 дней',
    additionalInfo1:
      '+ Дополнительно входит разбор совместимости с 1 человеком',
    additionalInfo2: '*Процесс исцеления может проходить через обострение.',
  },
  family: {
    title: '9 колен рода',
    desc1:
      '2 ступень Матрицы Судьбы. Проработка родовой матрицы дает социальную реализацию; показывает нашу значимость и задачу в социуме. Убирает глубинные негативные установки; нейтрализует повторение родовых сценариев. Реализация нашей матрицы зависит от родового квадрата, который составляет 50 % всей матрицы, поэтому 9 колен рода является следующим важнейшим этапом трансформации. Проработка поможет в реализации социального предназначения; и выполнении задач рода для получения дополнительного ресурса.',
    desc2:
      'Задача данного этапа, не столько понять и разобрать все проблемы рода, а их исцелить и проработать. Происходит расчет всех 9 колен рода, вскрытие энергетических накоплений рода мастером в канале инициации(яснознания), расчет примирения всех родов и отработка каждого ключа в процессе трансформации.',
    desc3:
      ' С помощью данного исцеления вы откроете дверь для своих детей и ваших потомков! Вы привлечете в свою жизнь и в свой род любовь, изобилие, процветание и глубинное единение. Данная проработка может помочь найти родственников, получить дар рода, встретить свою любовь, наладить отношения с родными, исцелить физические болезни, узнать о долгожданной беременности и т.д.',
    desc4:
      'Исправление ошибок родовых линий — это длительный и сложный процесс, но пройдя его вы получите гораздо больше.',
    videoMeeting: 'Видеовстреча 1 час + трансформация от >110 дней',
    additionalInfo1:
      '*Большое количество ритуальной работы и практик. Трансформации и исцеления могут проходить через обострения',
  },
  awareness: {
    title: 'Матрица осознанности',
    desc1:
      '3 ступень Матрицы судьбы. Проработка внутренней и внешней кармы. Получение своего кристалла души. Прохождение пути трансформации от 12 до 15 архетипа. Трансформация матрицы жертвы в матрицу нового видения.',
    desc2:
      'Избавление от душевных и физических страданий; стабилизация психологического состояния; понимание истинных желаний. Познание более глубинных процессов души и тела. Развитие телесности. Раскрытие и проработка подсознательных негативных сценариев и установок. Выявление теневой стороны личности и приумножение собственного ресурса. Создание духовного стержня. Освобождение от старых циклов, токсичных связей, переход в состояние любви и принятия.',
    desc3:
      'Данные расчёты показывают событийные сценарии в жизни человека и кармические истории, которые прорабатываются. В итоге человек получает алмазную огранку матрицы, то есть защиту судьбы.',
    listTitle: 'Вы получите расчеты',
    listEl1: 'Демон-Жертва;',
    listEl2: '16 Карм;',
    listEl3: 'Кристалл души;',
    listEl4: 'Матрица осознанности;',
    listEl5: 'Матрица внутренней кармы',
    videoMeeting: 'Видеовстреча 2 - 3 часа + трансформация от 60 дней',
    additionalInfo1:
      '*Большое количество ритуальной работы и практик. Трансформации и исцеления могут проходить через обострения',
  },
  prosperity: {
    title: 'Звезда процветания',
    desc1:
      '4 ступень Матрицы Судьбы. Выход на новый уровень реализации. Раскрытие своей путеводной звезды и энергий материализации желаний. Объединение уровней духа и материи, что открывает новый путь и методы реализации. Активация нового ключа силы. Осознание целостности 2-х миров.',
    desc2:
      'Матрица звезды процветания даст понимание как формируется идея, на чем она строится и что необходимо для реализации. Данная проработка поможет понять, что мешает воплощаться желаниям и как их можно реализовывать. Физически и энергетически слабая личность не способна удержать большое количество земных благ, так как все они являются очень энергоемкими.',
    desc3:
      'Проработка Звезды Процветания создает состояние целостности, благодаря которому человек получает более чистый поток для осознания истинных желаний и творческой реализации.',
    listTitle: 'Вы получите:',
    listEl1: 'ключи небесного и земного процветания;',
    listEl2: 'ключи результата и признания, почестей и известности;',
    listEl3: 'целостную звезду процветания и инструкцию по ее активации.',
    videoMeeting: 'Видеовстреча 2 - 3 часа + трансформация от 60 дней',
    additionalInfo1: '* Обязательно прохождение исцеления 9 колен рода',
    additionalInfo2:
      '* Включает в себя большое количество практической и ритуальной работы.',
  },
  conscience: {
    title: 'Осознанность',
    desc1:
      'Данная консультация вскрывает глубинную проблематику жизни и её исцеление, выход на новый уровень духовного развития. Проработка внутренней кармы. Открытие сердечной чакры, кристалла души и глубинных способностей личности.',
    desc2:
      'Данный расчёт показывает полный сценарий ситуаций в жизни человека и кармические истории, которые прорабатываются. В итоге человек получает алмазную огранку матрицы, то есть защиту судьбы.',
    listTitle: 'Входят расчет таких матриц:',
    listEl1: 'матрица души;',
    listEl2: 'матрица внутренней кармы;',
    listEl3: 'матрица осознанности;',
    listEl4: 'матрица врат света.',
    videoMeeting: 'Видеовстреча 90 минут + трансформация',
    additionalInfo1:
      'Требует минимального знакомства с базовым уровнем собственной матрицы!',
    additionalInfo2:
      '!Включает в себя большое количество практической и ритуальной работы. Процесс исцеления может проходить через обострение.',
  },
  coupleTherapy: {
    title: 'Парная терапия',
    desc1:
      'Диагностика взаимоотношений с партнёром (вторая половинка, родители, дети, друзья, знакомые, братья/сестры, коллеги).',
    desc2:
      'Поможет выявить цели и проблемы коммуникации; точки роста; задачи партнёров; ресурсное состояние. Работа с негативными и позитивными качествами каждого из партнёров; проработка факторов, которые мешают гармоничному существованию партнёров; разбор плюсов и минусов совместимости.',
    desc3:
      '+ Заключительные рекомендации для эффективного решения проблем, достижения общих целей и баланса в партнёрстве по итогам терапии.',
    videoMeeting:
      'Личная встреча или видеовстреча от 3-х часов ( 1-2 перерыва по 10 минут) + трансформация для партнеров',
    warning: '*ТРЕБУЕТСЯ ПРИСУТСТВИЕ ДВУХ ПАРТНЁРОВ!',
  },
  game: {
    title: 'Трансформационная игра “Кармические Путешествия”',
    desc1:
      'В основе игры лежат два метода: ТАРО и «Диагностика предназначения судьбы по дате рождения» Наталии Ладини. Форматы игры хорошо раскрывают практически любые запросы. Ведущая игры поможет игрокам исследовать свой запрос, найти причинно-следственные связи в жизненных ситуациях, получить конкретные рекомендации.',
    desc2:
      '! В игре можно принимать участие без определенного запроса. В таком случае, игра послужит отличным диагностическим инструментом для нескольких жизненных сфер.',
    listTitle: 'Спектр запросов для игры:',
    listEl1: 'жизненное предназначение;',
    listEl2: 'личностное развитие;',
    listEl3: 'вопросы здоровья;',
    listEl4: 'финансовые затруднения;',
    listEl5: 'поиск работы;',
    listEl6: 'цикличные ситуации;',
    listEl7: 'межличностные отношения и т.д.',
    videoMeeting: 'Видеовстреча от 90 минут',
    meeting: 'Встреча в г. Киев (локация согласовывается с участниками)',
    person: 'чел',
    groupGame: 'Групповая игра (2-4 человека)',
    personalGame: 'Индивидуальная игра',
  },
  parentChildren: {
    title: 'Детско-родительские отношения',
    listTitle: 'Данная консультация поможет:',
    listEl1: 'понять себя и своего ребёнка;',
    listEl2: 'выстроить правильную коммуникацию;',
    listEl3: 'уделить внимание определённым качествам ребёнка;',
    listEl4: 'направить в нужную сферу деятельности;',
    listEl5: 'эффективно решать конфликтные ситуации;',
    listEl6: 'выбрать верную модель поведения родителя;',
    listEl7: 'узнать таланты, способности, задачи и ключевые энергии ребёнка.',
    videoMeeting:
      'Видеовстреча 60 - 90 минут + индивидуальные рекомендации для ребенка и детско-родительских отношений + трансформация для родителя исходя из запроса',
    additionalInfo1: 'Матрица ребёнка, Совместимость 1 родителя с ребёнком',
    additionalInfo2:
      'Комплексная консультация: Счастливая семья (матрица ребёнка, совместимость ребёнка с каждым родителем, совместимость родителей)',
  },
  personalLife: {
    title: 'Личная жизнь',
    listTitle: 'Данная консультация поможет:',
    listEl1: 'наладить личную жизнь;',
    listEl2: 'найти вторую половинку;',
    listEl3: 'понять какой для вас комфортный тип взаимоотношений;',
    listEl4: 'прийти к взаимопониманию с партнером.',
    desc1:
      'Разбор личных качеств и почему не удается построить личную жизнь. Понять какой партнер подходит и какие потребности вам важно закрывать в отношениях.',
    desc2:
      'На трансформации будет возможность прокачать внутреннюю энергетику, усилить свои достоинства и проработать негативные установки, мешающие построить гармоничные отношения.',
    desc3: 'Разбор матрицы совместимости с 1 – 2 партнерами',
    videoMeeting: 'Видеовстреча 60 минут + трансформация 1 блок',
  },
  year: {
    title: 'Прогноз на год',
    desc1:
      'Позволяет узнать свои энергии в прошлом, текущем или будущем году. Включает в себя прогностику на каждые 22 дня, где прописаны все ключи данного периода и дана вариативность, как позитивных, так и негативных событий.',
    desc2:
      'Раскрытие задач, возможностей, испытаний данного года. Денежные и любовные аспекты года и возможности их реализаций.',
    videoMeeting:
      'Видеовстреча 30 - 40 минут + файл с прогностикой на каждые 22 дня + материалы для гармонизации года на усмотрение мастера',
    additionalInfo2:
      '*Возможность обратиться к мастеру за дополнительными разъяснениями и рекомендациями в течение всего года.',
  },
};

export default modalsRu;
