const modalsUa = {
  requests: 'Які запити розглянемо?',
  howIsGoingCons: 'Як проходить консультація?',
  buy: 'Купити',

  personal: {
    title: 'Цілісна матриця особистості',

    firstStep:
      'Перший рівень Матриці Долі є ідеальною основою для початку в пізнанні себе та своїх глибинних процесів. Матриця відповідає на багато питань, таких як:',
    stepsList1: 'Якими якостями я володію?',
    stepsList2: 'Яке моє призначення?',
    stepsList3: 'Які таланти у мене є?',
    stepsList4: 'Як я можу реалізувати себе?',
    stepsList5: 'Як заробити гроші?',
    stepsList6: 'Як побудувати особисте життя?',
    stepsList7: 'Куди піти навчатися?',
    stepsList8: 'Чи можу я відкрити свій бізнес?',
    stepsList9: 'Чому складні стосунки з рідними? Та інші.',
    desc1:
      'Матриця розкриє унікальні аспекти та таланти, що притаманні вашій особистості. Вона також виявить можливі виклики, випробування та негативні установки, що можуть бути спадковими з попередніх життів, але які можна перетворити на джерело сили та потенціалу.',
    desc2:
      "Особиста матриця розкриє тему здоров'я та психосоматичних процесів у вашому тілі. Це найглибша та найфундаментальніша трансформація у вашому житті.",
    desc3:
      'У майбутньому, саме ця основна інформація може бути розширена шляхом використання інших методів і підходів, що дозволять значно глибше вивчити свою особистість, а також виходити на нові рівні розуміння та свідомості.',
    videoMeeting: 'Відеозустріч 2 - 3 години + трансформація від 60 днів',
    additionalInfo1: '+ Додатково входить розбір сумісності з 1 людиною',
    additionalInfo2: '*Процес зцілення може проходити через загострення.',
  },
  family: {
    title: '9 колін роду',
    desc1:
      'Другий рівень Матриці Долі включає розгляд родової матриці, що дозволяє досягнути соціальної реалізації, а також виявити нашу роль та завдання у суспільстві. Цей рівень спрямований на вирішення глибинних негативних аспектів, а також на уникнення повторення родових сценаріїв. Родова матриця становить 50% всієї матриці, тому робота з 9 поколіннями роду є наступним найважливішим етапом трансформації. Робота з даним рівнем допоможе у реалізації соціального призначення та виконанні завдань роду для отримання додаткового ресурсу.',
    desc2:
      'Завдання цього етапу не тільки розібрати приховані проблеми в родовій системі, а їх зцілити й опрацювати. Проводиться розрахунок всіх 9 поколінь роду, розрахунок примирення ліній родів і відпрацювання кожного ключа в процесі трансформації.',
    desc3:
      "За допомогою цього зцілення ви відкриєте двері для своїх дітей та нащадків! Ви активуєте в своєму житті і житті свого роду любов, достаток, процвітання та глибоке об'єднання. Ця робота може допомогти знайти або підтримати близьких, отримати дар роду, зустріти своє кохання, покращити стосунки з родиною, зцілити фізичні хвороби, дізнатися про довгоочікувану вагітність тощо.",
    desc4:
      'Пропрацювання 9 колін роду - це тривалий і складний процес, але пройшовши його, ви отримаєте помітні результати.',
    videoMeeting: 'Відеозустріч 1 година + трансформація протягом >110 днів',
    additionalInfo1:
      '*Велика кількість ритуальної роботи та практик. Трансформації та зцілення можуть проходити через загострення',
  },
  awareness: {
    title: 'Матриця усвідомленості',
    desc1:
      '3 рівень Матриці Долі. Опрацювання внутрішньої та зовнішньої карми. Отримання свого кристала душі. Проходження шляху трансформації від 12 до 15 архетипу. Трансформація матриці жертви в матрицю нового бачення.',
    desc2:
      'Позбавлення душевних і фізичних страждань; стабілізація психологічного стану; розуміння справжніх бажань. Пізнання більш глибинних процесів душі та тіла. Розкриття та пропрацювання підсвідомих негативних сценаріїв. Виявлення тіньової сторони особистості та збільшення власного ресурсу. Звільнення від токсичних стосунків, перехід у стан любові й прийняття.',
    desc3:
      'Ці розрахунки показують сценарії подій у житті людини та кармічні історії, які пропрацьовуються. У результаті людина отримує діамантове ограновування матриці, тобто захист долі.',
    listTitle: 'Ви отримаєте розрахунки',
    listEl1: 'Демон-Жертва;',
    listEl2: '16 Карм;',
    listEl3: 'Кристал душі;',
    listEl4: 'Матриця усвідомленості;',
    listEl5: 'Матриця внутрішньої карми.',
    videoMeeting: 'Відеозустріч 2 години + трансформація від 70 днів',
    additionalInfo1:
      '*Велика кількість ритуальної роботи та практик. Трансформації та зцілення можуть проходити через загострення',
  },
  prosperity: {
    title: 'Зірка процвітання',
    desc1:
      "4 рівень Матриці Долі. Вихід на новий рівень реалізації. Розкриття своєї провідної зірки та енергій матеріалізації бажань. Об'єднання рівнів духа і матерії, що відкриває нові шляхи та методи реалізації. Активація нових сил. Усвідомлення цілісності двох світів.",
    desc2:
      'Матриця зірки процвітання надасть розуміння того, як формується ідея, на чому вона базується та що потрібно для її реалізації. Цей рівень допоможе зрозуміти, що заважає бажанням втілюватися та як їх можна реалізовувати. Фізично та енергетично слабка особистість не здатна утримати велику кількість земних благ, оскільки всі вони є дуже енергомісткими.',
    desc3:
      'Робота з Зіркою Процвітання створює стан цілісності, завдяки якому людина отримує світлий потік для усвідомлення справжніх бажань та творчої реалізації.',
    listTitle: 'Ви отримаєте:',
    listEl1: 'ключі небесного та земного процвітання;',
    listEl2: 'ключі результату, визнання, почестей та слави;',
    listEl3: 'цілісну зірку процвітання та інструкцію щодо її активації.',
    videoMeeting: 'Відеозустріч 2 години + трансформація протягом 60 днів',
    additionalInfo1: '* Необхідне проходження "9 колін роду".',
    additionalInfo2:
      '* Включає велику кількість практичної та ритуальної роботи.',
  },
  conscience: {
    title: 'Усвідомленість',
    desc1:
      'Ця консультація розкриває глибоку проблематику життя, приводить до нового рівня духовного розвитку. Розбір внутрішньої карми. Відкриття серцевої чакри, кристала душі та глибинних здібностей особистості.',
    desc2:
      'Цей розрахунок показує повний сценарій ситуацій у житті людини та кармічні історії, які проходимо. У результаті людина отримує діамантове ограновування матриці, тобто захист долі.',
    listTitle: 'У склад входять розрахунки таких матриць:',
    listEl1: 'матриця душі;',
    listEl2: 'матриця внутрішньої карми;',
    listEl3: 'матриця усвідомленості;',
    listEl4: 'матриця брами світла.',
    videoMeeting: 'Відеозустріч 90 хвилин + трансформація',
    additionalInfo1:
      '! Потребує мінімального знайомства з базовим рівнем власної матриці.',
    additionalInfo2:
      '!Містить у собі велику кількість практичної та ритуальної роботи. Процес зцілення може проходити через загострення.',
  },
  coupleTherapy: {
    title: 'Парна терапія',
    desc1:
      'Діагностика стосунків з партнером (кохана людина, батьки, діти, друзі, знайомі, брати/сестри, колеги).',
    desc2:
      'Допомагає виявити цілі й проблеми комунікації; точки зростання; завдання партнерів; ресурсний стан. Робота з негативними й позитивними якостями кожного з партнерів; опрацювання чинників, які заважають гармонійній взаємодії партнерів; розбір плюсів і мінусів сумісності.',
    desc3:
      "+ Остаточні рекомендації для ефективного розв'язання проблем, досягнення загальних цілей і балансу в партнерстві за результатами терапії.",
    videoMeeting:
      'Особиста зустріч або відеозустріч від 3-х годин (1-2 перерви по 10 хвилин) + трансформація для партнерів',
    warning: '*Необхідна присутність обох партнерів!',
  },
  game: {
    title: 'Трансформаційна гра "Кармічні Подорожі"',
    desc1:
      'Гра базується на двох методах: ТАРО і "Матриця долі" Наталії Ладіні. Формати гри добре розкривають практично будь-які запити. Гра допоможе гравцям дослідити їхній запит, або навіть декілька одразу, а також знайти причинно-наслідкові зв\'язки в життєвих ситуаціях і отримати конкретні рекомендації.',
    desc2:
      '! У грі можна брати участь без чіткого запиту. У такому випадку гра послужить чудовим діагностичним інструментом для декількох сфер життя.',
    listTitle: 'Спектр запитів для гри:',
    listEl1: 'життєве призначення;',
    listEl2: 'особистісний розвиток;',
    listEl3: "питання здоров'я;",
    listEl4: 'фінансові труднощі;',
    listEl5: 'пошук роботи;',
    listEl6: 'циклічні ситуації;',
    listEl7: 'міжособистісні відносини тощо.',
    videoMeeting: 'Відеозустріч від 90 хвилин',
    meeting: 'Зустріч у м. Київ (локація узгоджується з учасниками)',
    person: 'люд',
    groupGame: 'Групова гра(2-4 учасника)',
    personalGame: 'Індивідуальна гра',
  },
  parentChildren: {
    title: 'Дитячо-батьківські відносини',
    listTitle: 'Ця консультація допоможе:',
    listEl1: 'розуміти себе і свою дитину;',
    listEl2: 'побудувати правильну комунікацію;',
    listEl3: 'звернути увагу на певні якості дитини;',
    listEl4: 'спрямувати її у потрібну сферу діяльності;',
    listEl5: 'ефективно вирішувати конфліктні ситуації;',
    listEl6: 'вибрати певну модель поведінки для батьків;',
    listEl7: 'виявити таланти, здібності, завдання та ключові енергії дитини.',
    videoMeeting:
      'Відеозустріч 60-90 хвилин + індивідуальні рекомендації для дитини та дитячо-батьківських взаємин + трансформація для одного з батьків з урахуванням запиту.',
    additionalInfo1: 'Матриця дитини, Сумісність 1 з батьків з дитиною',
    additionalInfo2:
      'Комплексна консультація: Щаслива родина (матриця дитини, сумісність дитини з кожним з батьків, сумісність батьків)',
  },
  personalLife: {
    title: 'Особисте життя',
    listTitle: 'Ця консультація допоможе:',
    listEl1: 'налагодити особисте життя;   ',
    listEl2: 'знайти другу половинку;',
    listEl3: 'зрозуміти, який тип взаємовідносин комфортний для вас;',
    listEl4: 'досягти взаєморозуміння з партнером.',
    desc1:
      'Аналіз особистих якостей та причин, чому не вдається побудувати особисте життя. Розбір типажу партнера, що підходить, і які потреби важливо задовольняти у відносинах.',
    desc2:
      'Під час трансформації ви зможете посилити внутрішню енергетику, підвищити свою цінність та працювати над негативними сторонами, які заважають налагодити гармонійні взаємини.',
    desc3: 'Аналіз матриці сумісності з 1-2 партнерами.',
    videoMeeting: 'Відеозустріч 60 хвилин + трансформація 1 блок',
  },
  year: {
    title: 'Прогноз на рік',
    desc1:
      'Дозволяє дізнатися про свої енергії в минулому, теперішньому чи майбутньому році. Містить в собі прогноз на кожні 22 дні, де описані всі ключові події цього періоду і надана варіативність як позитивних, так і негативних подій.',
    desc2:
      'Розкриття завдань, можливостей і випробувань цього року. Аспекти фінансів та особистого життя протягом року та можливості щодо їх позитивної реалізації.',
    videoMeeting:
      'Відеозустріч 40 хвилин + файл з прогнозом на кожні 22 дні + матеріали для гармонізації року на розсуд фахівця.',
    additionalInfo2:
      '*Можливість звернутися до фахівця за додатковими поясненнями та рекомендаціями протягом усього року.',
  },
};

export default modalsUa;
